import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Menu from "./menu"
import "../styles/components/_header.scss"

const menuClick = e => {
  if (!e.currentTarget.parentNode.classList.contains("menu-open")) {
    e.currentTarget.parentNode.classList.add("menu-open")
  } else {
    e.currentTarget.parentNode.classList.remove("menu-open")
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allContentfulHeader {
          nodes {
            iconMenu {
              description
              file {
                url
              }
            }
            iconClose {
              description
              file {
                url
              }
            }
            logo {
              description
              file {
                url
              }
            }
            menuName
            menu {
              label
              url
            }
            mobileAddOns {
              label
              url
            }
          }
        }
        allContentfulGeneral {
          nodes {
            redUnderline {
              description
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header id="header" className="header" key="header-component">
        <Link to="/">
          <img
            src={data.allContentfulHeader.nodes[0].logo.file.url}
            alt={data.allContentfulHeader.nodes[0].logo.description}
            className="logo"
          />
        </Link>
        <Menu
          menuName={data.allContentfulHeader.nodes[0].menuName}
          menuItems={data.allContentfulHeader.nodes[0].menu}
          key="header-menu"
        />
        <div
          className="underline"
          style={{
            backgroundImage: `url(${data.allContentfulGeneral.nodes[0].redUnderline.file.url})`,
          }}
        ></div>
        <Menu
          menuName="Mobile Add-Ons"
          menuItems={data.allContentfulHeader.nodes[0].mobileAddOns}
          className="mobile-addons"
        ></Menu>
        <div className="menu-operator" onClick={menuClick}>
          <img
            src={data.allContentfulHeader.nodes[0].iconMenu.file.url}
            alt={data.allContentfulHeader.nodes[0].iconMenu.description}
            className="menu-icon"
          />
          <img
            src={data.allContentfulHeader.nodes[0].iconClose.file.url}
            alt={data.allContentfulHeader.nodes[0].iconClose.description}
            className="close-icon"
          />
        </div>
      </header>
    )}
  />
)
