import React from "react"
import Menu from "./menu"
import "../styles/components/_footer.scss"

const Footer = () => {
  const data = {
    contentfulFooter: {
      copyright: "Talent Inc., All Rights Reserved.",
      socialMedia: [
        {
          icon: {
            description: "Icon for Facebook",
            file: {
              url:
                "//images.ctfassets.net/wqrz7x4dpuaz/aKORQLyV326xXYwiCmDWH/197ba7bcb467ee8b53d08dbc2972ee97/facebook-icon.svg",
            },
          },
          url: "https://www.facebook.com/WeAreTalentInc",
        },
        {
          icon: {
            description: "Icon for Twitter",
            file: {
              url:
                "//images.ctfassets.net/wqrz7x4dpuaz/4UJv8s67Uozp095uaTNmFI/af8a8b0e33e32d93ec3b9f0b1c755432/twitter-icon.svg",
            },
          },
          url: "https://twitter.com/WeAreTalentInc",
        },
        {
          icon: {
            description: "Icon for LinkedIn",
            file: {
              url:
                "//images.ctfassets.net/wqrz7x4dpuaz/7e6EMY9IGD4WnuF8ETbHHy/ba04d5b188c38c7030ddd5081b1aa928/linkedin-icon.svg",
            },
          },
          url: "https://www.linkedin.com/company/talentinc",
        },
      ],
      productsMenuName: "Products",
      productsMenu: [
        {
          label: "TopResume",
          url: "https://www.topresume.com/",
        },
        {
          label: "TopCV",
          url: "https://www.topcv.com/",
        },
        {
          label: "TopInterview",
          url: "https://www.topinterview.com/",
        },
        {
          label: "ResumeRabbit",
          url: "https://www.resumerabbit.com/",
        },
      ],
      companyMenuName: "Company",
      logo: {
        description: "Talent Inc Logo",
        file: {
          url:
            "//images.ctfassets.net/wqrz7x4dpuaz/58x2YoJmswyfPjXbng514n/6347f088b262ccaa0faad310dff4a2fc/talentinc_logo.svg",
        },
      },
      companyMenu: [
        {
          label: "About Us",
          url: "about",
        },
        {
          label: "Contact Us",
          url: "contact",
        },
        {
          label: "Careers",
          url: "careers",
        },
        {
          label: "Partnerships",
          url: "partnerships",
        },
        {
          label: "Press",
          url: "press",
        },
      ],
      legalLinks: [
        {
          url: "terms",
          text: "Terms & Conditions",
        },
        {
          url: "privacy",
          text: "Privacy Policy",
        },
        {
          url: "do-not-sell",
          text: "Do Not Sell",
        },
      ],
    },
  }

  return (
    <footer>
      <div className="footer-info">
        <img
          src={data.contentfulFooter.logo.file.url}
          alt={data.contentfulFooter.logo.description}
          className="logo"
        />
        <ul className="social-media">
          {data.contentfulFooter.socialMedia.map((socialItem, n) => {
            return (
              <li key={n}>
                <a
                  href={socialItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={socialItem.icon.file.url}
                    alt={socialItem.icon.description}
                  />
                </a>
              </li>
            )
          })}
        </ul>
        <ul className="legal-links">
          {data.contentfulFooter.legalLinks.map((linkItem, n) => {
            return (
              <li key={n}>
                <a className="legal-link" href={linkItem.url}>
                  {linkItem.text}
                </a>
              </li>
            )
          })}
        </ul>
        <div className="copyright">{`© ${new Date().getFullYear()} ${
          data.contentfulFooter.copyright
        }`}</div>
      </div>
      <div className="menus">
        <Menu
          menuName={data.contentfulFooter.productsMenuName}
          menuItems={data.contentfulFooter.productsMenu}
        />
        <Menu
          menuName={data.contentfulFooter.companyMenuName}
          menuItems={data.contentfulFooter.companyMenu}
        />
      </div>
    </footer>
  )
}

export default Footer
