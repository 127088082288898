import React from "react"
import { Link } from "gatsby"
import "../styles/components/_menu.scss"

const Menu = ({ menuName, menuItems }) => {
  const menuClass = menuName.replace(" ", "-").toLowerCase()

  return (
    <div className={`menu ${menuClass}`}>
      <h3>{menuName}</h3>
      <ul>
        {menuItems.map((item, n) => {
          if (item.url.includes("//")) {
            return (
              <li key={n}>
                <a href={item.url} rel="noopener noreferrer" key={n}>
                  {item.label}
                </a>
              </li>
            )
          } else {
            return (
              <li key={n}>
                <Link to={`/${item.url}`}>{item.label}</Link>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default Menu
