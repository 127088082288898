import React from "react"
import { Helmet } from "react-helmet"

const Meta = ({ title, description, image, url, imgAlt, canonicalUrl }) => {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta
        http-equiv="Cache-control"
        content="public, max-age=0, must-revalidate"
      />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>{`Talent Inc. | ${title}`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`https:${image}`} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:site_name" content="Talent Inc." />
      <meta name="twitter:image:alt" content={imgAlt} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  )
}

export default Meta
